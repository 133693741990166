@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-BoldItalic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold-Italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-Bold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-ExtraBoldItalic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-ExtraBold-Italic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-ExtraBold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-Italic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-MediumItalic.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Medium-Italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-Medium.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('/assets/font/jetbrains/JetBrainsMono-Regular.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* montserrat-700 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/assets/font/montserrat/montserrat-v25-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
